import * as types from "../actions/types";
const initialState = {
    datatype:null,
    anadata:[],
    extradata:[],
    general:[],
    contentdata:[],
    orders:[],
    sellorders:[],
    buyorders:[],
    currentOrder: null,
};

const generalReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.CREATE:
            return {
                ...state,
                contentdata: action.payload,
            };

        case types.UPDATE:
            return {
                ...state,
                currentOrder: action.payload,
            };
        case types.LIST_DATA:

            return {
                ...state,
                contentdata: action.payload,


            };
        case types.LIST_USERS:
            return {
                ...state,
                extradata: {
                    ...state.extradata,
                    [action.datatype]: action.payload,
                }

            };
        case types.DELETE:
            return {
                ...state,
                contentdata: state.contentdata.filter((post) => post._id !== action.payload),
                currentOrder: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default generalReducer;
