import antdTrTR from 'antd/es/locale/tr_TR';
import trMsg from "../locales/tr_TR.json";

const TrLang = {
    antd: antdTrTR,
    locale: 'tr-TR',
    messages: {
        ...trMsg
    },
};
export default TrLang;
