import {
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  CreditCardOutlined,
  FundOutlined,
  TabletFilled,
  CameraOutlined,
  VideoCameraOutlined,
  ShoppingCartOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const finansNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/dasboards`,
    title: 'home',
    icon: DesktopOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'settings',
    icon: FileDoneOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'bots',
    path: `${APP_PREFIX_PATH}/bots`,
    title: 'spotbots',
    icon: RobotOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'futures',
    path: `${APP_PREFIX_PATH}/futures`,
    title: 'futures',
    icon: RobotOutlined,
    breadcrumb: true,
    submenu: [],
  }
  ,
  {
    key: 'trailing',
    path: `${APP_PREFIX_PATH}/trailing`,
    title: 'trailing',
    icon: RobotOutlined,
    breadcrumb: true,
    submenu: [],
  }
  ,
  {
    key: 'markets',
    path: `${APP_PREFIX_PATH}/markets`,
    title: 'markets',
    icon: EnvironmentOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'apisettings',
    path: `${APP_PREFIX_PATH}/apisettings`,
    title: 'apisettings',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  }
  ,
  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    title: 'profile',
    icon: EnvironmentOutlined,
    breadcrumb: true,
    submenu: [],
  }
  ,
  {
    key: 'currencies',
    path: `${APP_PREFIX_PATH}/currencies`,
    title: 'currencies',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'orders',
    path: `${APP_PREFIX_PATH}/orders`,
    title: 'orders',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'sidenav.users',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'languages',
    path: `${APP_PREFIX_PATH}/languages`,
    title: 'sidenav.languages',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [],
  }


  ]






const navigationConfig = [

  ...finansNavTree


]

export default navigationConfig;
