import jwtDecode from "jwt-decode";
import { useCookies } from 'react-cookie';
export default function Auth() {
    const enc = Buffer.from(window.navigator.appVersion, 'utf8').toString('base64');
    const [cookies] = useCookies();
    if (localStorage.getItem("gsdhhrxchbhlkpkakd")) {

        try {
            const jwt_Token_decoded = jwtDecode(localStorage.getItem("gsdhhrxchbhlkpkakd"));
            if(cookies[jwt_Token_decoded.cjwtpto]!==enc)  {
                localStorage.clear();
                return false;
            }

            if (jwt_Token_decoded.exp * 1000 < Date.now()) {
                localStorage.clear();
                return false;
            }
                else {
                   // console.log("token devam ediyor");
                    return true;
                }


        } catch (err) {
            localStorage.clear();
            //console.log(err);
            return false;
        }

    } else {
        localStorage.clear();
        return false;
    }

}
