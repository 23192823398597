import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
export const AppViews = () => {


  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
        <Route path={`${APP_PREFIX_PATH}/payment`} component={lazy(() => import(`./payment`))} />
        <Route path={`${APP_PREFIX_PATH}/markets`} component={lazy(() => import(`./markets`))} />
        <Route path={`${APP_PREFIX_PATH}/apisettings`} component={lazy(() => import(`./apisettings`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/banners`} component={lazy(() => import(`./banners`))} />
        <Route path={`${APP_PREFIX_PATH}/bots`} component={lazy(() => import(`./bots`))} />
        <Route path={`${APP_PREFIX_PATH}/trailing`} component={lazy(() => import(`./trailing`))} />
        <Route path={`${APP_PREFIX_PATH}/trailingorders`} component={lazy(() => import(`./trailingorders`))} />
        <Route path={`${APP_PREFIX_PATH}/futures`} component={lazy(() => import(`./futures`))} />
        <Route path={`${APP_PREFIX_PATH}/futuresorders`} component={lazy(() => import(`./futuresorders`))} />
        <Route path={`${APP_PREFIX_PATH}/videos`} component={lazy(() => import(`./videos`))} />
        <Route path={`${APP_PREFIX_PATH}/balances`} component={lazy(() => import(`./balances`))} />
        <Route path={`${APP_PREFIX_PATH}/currencies`} component={lazy(() => import(`./currencies`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/languages`} component={lazy(() => import(`./languages`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>

    </Suspense>
  )
}

export default React.memo(AppViews);
