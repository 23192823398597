import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import orders from "./orders";
import general from "./general";
const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    general: general,
    orders: orders
});

export default reducers;
