import * as types from "../actions/types";

const initialState = {
    orders:[],
    sellorders:[],
    buyorders:[],
    currentOrder: null,
};

const orderReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.FETCH_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case types.FETCH_ORDERS_BYFIELD:
            return {
                ...state,
                orders: action.payload,
            };
        case types.FETCH_ORDERS_BYFIELD_BUY:

            return {
                ...state,
                orders: action.payload,
                buyorders: action.payload
            };
        case types.FETCH_ORDERS_BYFIELD_SELL:
            return {
                ...state,
                orders: action.payload,
                sellorders:action.payload
            };
        case types.FETCH_SINGLE_ORDER:
            return {
                ...state,
                currentOrder: action.payload,
            };
        case types.CREATE_ORDER:
            return {
                ...state,
                orders: [...state.orders, action.payload],
            };
        case types.UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map((post) => {
                    if (post._id === action.payload._id) {
                        return action.payload;
                    } else {
                        return post;
                    }
                }),
                currentPost: action.payload,
            };
        case types.DELETE_ORDER:
            return {
                ...state,
                orders: state.orders.filter((post) => post._id !== action.payload),
                currentOrder: null,
            };
        default:
            return {
                ...state,
            };
    }
};

export default orderReducer;
